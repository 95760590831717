import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { BookingsService } from './services/bookings.service';
import { LoadingComponent } from './components/loading/loading.component';
import { environment } from '../environments/environment';
import { DevComponent } from './components/dev/dev.component';

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [RouterOutlet, LoadingComponent, DevComponent],
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss'
})
export class AppComponent {
	error: string = '';
	currentDate: string = '';
	activities: any[] = [];
	selectedDate: string = '';
	isLoading: boolean = true;
	isProdMode: boolean = environment.production;
	constructor(
		private bookingsService: BookingsService
	) {

	}

	ngOnInit(): void {
		const date = new Date();
		const options: Intl.DateTimeFormatOptions = {
			day: 'numeric',
			month: 'long',
			year: 'numeric'
		};
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();
		this.selectedDate = `${year}-${month}-${day}`;
		this.currentDate = new Intl.DateTimeFormat('es-ES', options).format(date);
		this.getBookings();
	}

	setDate(inputDate: any) {
		const date = new Date(`${inputDate}T00:00`);
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();
		this.selectedDate = `${year}-${month}-${day}`;

		const options: Intl.DateTimeFormatOptions = {
			day: 'numeric',
			month: 'long',
			year: 'numeric'
		};
		this.currentDate = new Intl.DateTimeFormat('es-ES', options).format(date);
		this.getBookings();
	}
	getBookings() {
		const day = this.bookingsService.getDayName(this.selectedDate);
		const month = this.bookingsService.getMonthName(this.selectedDate);
		this.isLoading = true;
		this.bookingsService.getBookings(this.selectedDate, day, month).subscribe({
			next: response => {
				this.activities = response.data;
				this.isLoading = false;
			},
			error: error => {
				this.isLoading = false;
			}
		});
	}
}
