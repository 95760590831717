import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class BookingsService {
	constructor(private http: HttpClient) { }

	getBookings(date: string, day: string, month: string) {
		return this.http.get<any>(`${environment.apiUrl}disponibilidad?date=${date}&month=${month}&day=${day}`);
	}

	getDayName(date: string, locale = 'es-AR') {
		const formattedDate = new Date(date);
		return formattedDate.toLocaleDateString(locale, { weekday: 'long', timeZone: 'UTC' });
	}

	getMonthName(date: string, locale = 'es-AR') {
		const formattedDate = new Date(date);
		return formattedDate.toLocaleDateString(locale, { month: 'long', timeZone: 'UTC' });
	}
}
