<div class=" max-w w-full mx-auto mt-5">
    <div class="animate-pulse flex space-x-4">
        <div class="flex-1 space-y-6 py-1">
            <div class="space-y-3">
                <div class="grid grid-cols-3 gap-4">
                    <div class="h-20 bg-slate-200 rounded col-span-1"></div>
                    <div class="h-20 bg-slate-200 rounded col-span-1"></div>
                    <div class="h-20 bg-slate-200 rounded col-span-1"></div>
                </div>
            </div>
           
        </div>
    </div>
</div>
<div class=" max-w w-full mx-auto mt-5">
    <div class="animate-pulse flex space-x-4">
        <div class="flex-1 space-y-6 py-1">
            <div class="space-y-3">
                <div class="grid grid-cols-3 gap-4">
                    <div class="h-20 bg-slate-200 rounded col-span-1"></div>
                    <div class="h-20 bg-slate-200 rounded col-span-1"></div>
                    <div class="h-20 bg-slate-200 rounded col-span-1"></div>
                </div>
            </div>
           
        </div>
    </div>
</div>
<div class=" max-w w-full mx-auto mt-5">
    <div class="animate-pulse flex space-x-4">
        <div class="flex-1 space-y-6 py-1">
            <div class="space-y-3">
                <div class="grid grid-cols-3 gap-4">
                    <div class="h-20 bg-slate-200 rounded col-span-1"></div>
                    <div class="h-20 bg-slate-200 rounded col-span-1"></div>
                    <div class="h-20 bg-slate-200 rounded col-span-1"></div>
                </div>
            </div>
           
        </div>
    </div>
</div>