@if(!isProdMode){
	<app-dev></app-dev>
}
<div class="flex flex-col px-5 py-5">

	<div class="flex items-center justify-between">
		<div class="flex">
			<div class="flex flex-col">
				<h1>Ski Nórdico S.R.L</h1>
				<h2>Disponibilidad {{currentDate}}</h2>
			</div>
		</div>
		<div>
			<input type="date" class="form__input__text" (change)="setDate(date.value)" #date />
		</div>
	</div>
	@if(isLoading){
	<app-loading />
	}@else{
	@if(activities.length === 0){
	<div>No se encontraron actividades para esta fecha</div>
	}@else{
	<div class="mt-10">
		@for(activity of activities; track activity){
		<div class="mt-5"><strong>{{activity[0].name}}</strong></div>
		<div class="booking__container">

			@for(schedule of activity; track schedule){
			<div class="booking__activity">
				<div class="flex justify-between">
					<div>{{schedule.hour_from}} a {{schedule.hour_to}}</div>
					@if(schedule.availables <= schedule.percentaje && schedule.availables !== 0){ 
						@if(activity[0].name === 'Noche Nórdica'){
							<div>Consultar</div>
						}@else{
							<div>{{schedule.availability}}</div>
						}
						
					}
					@if(schedule.busy === 0 ){
						<div class="badge primary">DISPONIBLE</div>
					}
					@if(schedule.availables > schedule.percentaje ){
						<div class="badge primary">DISPONIBLE</div>
					}
					@if(schedule.availables === 0){
						<div class="badge danger">AGOTADO</div>
					}
				</div>
			</div>
		}
	</div>
	}
</div>

}
}

</div>